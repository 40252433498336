import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import { document } from 'browser-monads'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import ContactHero from '../components/contact/hero'
import BlockContent from '../components/block-content'

const ContactPage = ({ data }) => {
  const
    { hero, _rawFormTitle, info } = data.contact,
    {_rawAddress, phones } = info;

  useEffect(() => {
    const body = document.querySelector('body')
    const footer = document.querySelector('footer')

    body.classList.add('black')
    footer.classList.add('black')
  })

  return(
    <Layout>
      <SEO title="Contact"/>

      <ContactHero hero={hero} />

      <section id="contact-form">
        <form className="grid" name='CasaVasalto-Website-Form' method='post' action="/success" data-netlify='true' data-netlify-honeypot='bot-field' autoComplete='off'>

          <input type='hidden' name='bot-field'/>
          <input type='hidden' name='form-name' value='CasaVasalto-Website-Form'/>

          <BlockContent className="block-bold extra" blocks={_rawFormTitle || []}/>
          <fieldset className="field-name" data-sal="fade" data-sal-duration="500">
            <label htmlFor="name">name</label>
            <input type="text" name="name" placeholder="Carla López Malo"/>
          </fieldset>
          <fieldset className="field-message" data-sal="fade" data-sal-duration="500">
            <label htmlFor="message">message</label>
            <textarea name="message" placeholder="Write your message"></textarea>
            <button  className="action-white" type="submit" aria-label="Send Form">send</button>
          </fieldset>
          <fieldset className="field-mail" data-sal="fade" data-sal-duration="500">
            <label htmlFor="email">e-mail</label>
            <input type="email" name="email" placeholder="hola@casavasalto.com"/>
          </fieldset>
          <fieldset className="field-phone" data-sal="fade" data-sal-duration="500">
            <label htmlFor="phone">phone</label>
            <input type="text" name="phone" placeholder="2223089988"/>
          </fieldset>

          <div className="info" data-sal="fade" data-sal-duration="500">
            <p>come visit</p>
            <BlockContent className="address" blocks={_rawAddress || []}/>
            {phones && phones.map((phone, i) => (
              <p key={i}>{`T${i + 1}: ${phone}`}</p>
            ))}
          </div>
        </form>
      </section>

    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    contact: sanityContact {
      hero {
        _rawTitle
        _rawText
        image {
          alt
          ...Image
        }
      }
      info {
        _rawAddress
        phones
      }
      _rawFormTitle
    }
  }
`
export default ContactPage
