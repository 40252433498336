import React from 'react'
import Image from 'gatsby-plugin-sanity-image'

import BlockContent from '../block-content'

const ContactHero = ({ hero }) => {
  return(
    <section id="contact-hero">
      <div className="grid layer-top" data-sal="slide-up" data-sal-delay="100" data-sal-duration="850">
        <BlockContent className="block-bold large" blocks={hero._rawTitle || []} />
        <BlockContent className="block-small" blocks={hero._rawText || []} />
      </div>
      <div className="grid layer-bottom" data-sal="fade" data-sal-delay="300" data-sal-duration="650">
        <div className="image-container">
          <Image {...hero.image} className="image" width={700} alt={hero.image.alt}/>
        </div>
      </div>
    </section>
  )
}

export default ContactHero
